import { Footer } from './Footer';
import { ConnectButton } from '../ConnectButton';
import { Marquee } from '@onbeam/ui';
import { css } from '@onbeam/styled-system/css';
import { vstack } from '@onbeam/styled-system/patterns';

export const ConnectWalletApplet = () => {
  return (
    <div
      className={vstack({
        gap: '2',
        align: 'center',
        h: '[380px]',
        justify: 'space-between',
      })}
    >
      <Marquee speed={70000}>connect wallet to unlock -</Marquee>
      <div className={css({ w: '[100%]' })}>
        <ConnectButton size="lg" />
        <Footer className={css({ pt: '10' })} />
      </div>
    </div>
  );
};
