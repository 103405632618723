import { ChainId } from '@beamswap/sdk';
import MULTICALL_ABI from './abi.json';
import CONTRACT_ADDRESSES from '../../contract_addresses.json';

const { TEST_multicall, DEV_multicall, MAIN_multicall } = CONTRACT_ADDRESSES;

const MULTICALL_NETWORKS: Partial<{ [chainId in ChainId]: string }> = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.XP]: DEV_multicall,
  [ChainId.BEAM_TESTNET]: TEST_multicall,
  [ChainId.BEAM]: MAIN_multicall,
};

export { MULTICALL_ABI, MULTICALL_NETWORKS };
