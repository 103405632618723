import { css } from '@onbeam/styled-system/css';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

export const Wrapper = (
  props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
) => (
  <div
    className={css({
      position: 'relative',
    })}
    {...props}
  />
);
