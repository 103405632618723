import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import ApplicationUpdater from '../../state/application/updater';
import ListsUpdater from '../../state/lists/updater';
import MulticallUpdater from '../../state/multicall/updater';
import TransactionUpdater from '../../state/transactions/updater';
import UserUpdater from '../../state/user/updater';
import { useAccount, WagmiProvider } from 'wagmi';
import store from '../../state';
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import { Provider as ReduxProvider } from 'react-redux';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { NetworkContextName } from '../../constants';
import { beamClient } from '../../lib/beam/client';
import { wagmiConfig } from '../../lib/wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Web3Provider } from '@ethersproject/providers';
import { rainbowKitTheme } from '@onbeam/ui';
import useConnectedChain from '../../hooks/useConnectedChain';

const ReduxUpdaters = () => (
  <>
    <ListsUpdater />
    <UserUpdater />
    <ApplicationUpdater />
    <TransactionUpdater />
    <MulticallUpdater />
  </>
);

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

const Web3Providers: FC<PropsWithChildren> = ({ children }) => {
  const { connector } = useAccount();
  const { chainId } = useConnectedChain();
  const [provider, setProvider] = useState<Web3Provider>();

  // biome-ignore lint/correctness/useExhaustiveDependencies: React to chain changes
  useEffect(() => {
    if (!connector?.getProvider) {
      return setProvider(undefined);
    }
    connector.getProvider().then((connectorProvider) => {
      setProvider(new Web3Provider(connectorProvider as any));
    });
  }, [connector, chainId]);

  const getLibrary = useCallback(
    (libraryProvider: any): Web3Provider => {
      const library = provider || new Web3Provider(libraryProvider);
      library.pollingInterval = 15000;
      return library;
    },
    [provider],
  );

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <ReduxProvider store={store}>
          <ReduxUpdaters />
          {children}
        </ReduxProvider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // In order to support bigint
      structuralSharing: false,
    },
  },
});

const Providers: FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    if (!beamClient) return;
    beamClient.connectProvider();
  }, []);

  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider modalSize="compact" theme={rainbowKitTheme}>
          <Web3Providers>{children}</Web3Providers>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default Providers;
