import { AutoColumn } from '../Column';
import React, {
  ComponentProps,
  DetailedHTMLProps,
  HTMLAttributes,
  PropsWithChildren,
} from 'react';
import { AlertTriangle } from 'react-feather';
import { css } from '@onbeam/styled-system/css';
import { text } from '@onbeam/styled-system/patterns';

export const Wrapper = ({
  children,
  ...props
}: PropsWithChildren & { id?: string }) => (
  <div className={css({ position: 'relative' })} {...props}>
    {children}
  </div>
);

export const TruncatedText = ({
  children,
  ...props
}: DetailedHTMLProps<
  HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>) => (
  <p
    className={css({
      textOverflow: 'ellipsis',
      width: '[220px]',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    })}
    {...props}
  >
    {children}
  </p>
);

const SwapCallbackErrorInner = ({ children, ...props }: PropsWithChildren) => (
  <div
    className={css({
      backgroundColor: 'red.400/10',
      borderRadius: 'lg',
      display: 'flex',
      alignItems: 'center',
      w: 'full',
      p: '[3rem 1.25rem 1rem 1rem]',
      mt: '[-2rem]',
      zIndex: '-1',
    })}
    {...props}
  >
    {children}
  </div>
);

const SwapCallbackErrorInnerAlertTriangle = ({
  children,
  ...props
}: PropsWithChildren) => (
  <div
    className={css({
      backgroundColor: 'red.400/10',
      borderRadius: 'md',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      mr: '[12px]',
      minWidth: '[48px]',
      height: '[48px]',
    })}
    {...props}
  >
    {children}
  </div>
);

export function SwapCallbackError({ error }: { error: string }) {
  return (
    <SwapCallbackErrorInner>
      <SwapCallbackErrorInnerAlertTriangle>
        <AlertTriangle size={24} />
      </SwapCallbackErrorInnerAlertTriangle>
      <p className={text({ style: 'base', color: 'red.400' })}>{error}</p>
    </SwapCallbackErrorInner>
  );
}

export const SwapShowAcceptChanges = ({
  ...props
}: ComponentProps<typeof AutoColumn>) => (
  <AutoColumn
    className={css({
      backgroundColor: 'purple.200/10',
      p: '[0.5rem]',
      borderRadius: 'lg',
      mt: '2',
    })}
    {...props}
  />
);
