import { css } from '@onbeam/styled-system/css';
import { flex, vstack } from '@onbeam/styled-system/patterns';
import { Card, PixelBackground } from '@onbeam/ui';
import { PropsWithChildren } from 'react';

export const DocumentLayout = ({ children }: PropsWithChildren) => (
  <main
    className={flex({
      w: 'full',
      h: '[calc(100vh - token(spacing.16))]',
      top: '16',
      overflow: 'hidden',
      pos: 'absolute',
      justify: 'center',
    })}
  >
    <Card
      size="md"
      className={css({
        position: 'absolute',
        top: '0',
        maxW: '[52rem]',

        '@media (max-width: 52rem)': {
          rounded: 'none',
        },
      })}
    >
      <PixelBackground
        className={vstack({
          gap: '3',
          overflowY: 'scroll',
          maxH: '[calc(100vh - token(spacing.20) - token(spacing.2))]',
          p: '6',
        })}
      >
        {children}
      </PixelBackground>
    </Card>
  </main>
);
