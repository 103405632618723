import { css, cx } from '@onbeam/styled-system/css';
import { flex } from '@onbeam/styled-system/patterns';
import { ComponentProps, DetailedHTMLProps, HTMLAttributes } from 'react';

const Row = ({
  className,
  style,
  $align,
  $padding,
  $border,
  $borderRadius,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  className?: string;
  $align?: string;
  $padding?: string;
  $border?: string;
  $borderRadius?: string;
}) => (
  <div
    className={cx(className, css({ w: 'full', display: 'flex', p: '0' }))}
    style={{
      alignItems: $align ? $align : 'center',
      padding: $padding,
      border: $border,
      borderRadius: $borderRadius,
      ...style,
    }}
    {...props}
  />
);

export const RowBetween = ({
  children,
  ...props
}: ComponentProps<typeof Row>) => (
  <Row className={css({ justifyContent: 'space-between' })} {...props}>
    {children}
  </Row>
);

export const RowFlat = (
  props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
) => <div className={flex({ alignItems: 'flex-end' })} {...props} />;

export const AutoRow = ({
  children,
  gap,
  justify,
  style,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  gap?: string;
  justify?: string;
}) => (
  <Row
    className={css({ flexWrap: 'wrap' })}
    style={{
      margin: gap && `-${gap}`,
      justifyContent: justify,
      ...style,
    }}
    {...props}
  >
    {children}
  </Row>
);

export const RowFixed = ({
  children,
  gap,
  style,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  gap?: string;
  justify?: string;
}) => (
  <Row
    className={css({ w: '[fit-content]' })}
    style={{
      margin: gap && `-${gap}`,
      ...style,
    }}
    {...props}
  >
    {children}
  </Row>
);

export default Row;
