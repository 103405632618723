import { flex, text } from '@onbeam/styled-system/patterns';
import { network } from '../../connectors';
import { NetworkContextName } from '../../constants';
import { useEagerConnect, useInactiveListener } from '../../hooks';
import { useWeb3React } from '@web3-react/core';
import React, { useState, useEffect, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '@onbeam/ui';

const MessageWrapper = ({ children, ...props }: PropsWithChildren) => (
  <div
    className={flex({
      alignItems: 'center',
      justifyContent: 'center',
      h: '[20rem]',
    })}
    {...props}
  >
    {children}
  </div>
);

export default function Web3ReactManager({
  children,
}: { children: JSX.Element }) {
  const { t } = useTranslation();
  const { active } = useWeb3React();
  const {
    active: networkActive,
    error: networkError,
    activate: activateNetwork,
  } = useWeb3React(NetworkContextName);

  // try to eagerly connect to an injected provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // after eagerly trying injected, if the network connect ever isn't active or in an error state, activate itd
  useEffect(() => {
    if (triedEager && !networkActive && !networkError && !active) {
      activateNetwork(network);
    }
  }, [triedEager, networkActive, networkError, activateNetwork, active]);

  // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists
  useInactiveListener(!triedEager);

  // handle delayed loader state
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoader(true);
    }, 600);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  // on page load, do nothing until we've tried to connect to the injected connector
  if (!triedEager) {
    return null;
  }

  // if the account context isn't active, and there's an error on the network context, it's an irrecoverable error
  if (!active && networkError) {
    return (
      <MessageWrapper>
        <h2 className={text({ color: 'purple.200' })}>{t('unknownError')}</h2>
      </MessageWrapper>
    );
  }

  // if neither context is active, spin
  if (!active && !networkActive) {
    return showLoader ? (
      <MessageWrapper>
        <Loader />
      </MessageWrapper>
    ) : null;
  }

  return children;
}
