import { css } from '@onbeam/styled-system/css';
import { PropsWithChildren } from 'react';

export const Dots = ({ children, ...props }: PropsWithChildren) => (
  <span
    className={css({
      _after: {
        display: 'inline-block',
        animation: 'ellipsis 1.25s infinite',
        content: '"."',
        w: '[1rem]',
        textAlign: 'left',
      },
    })}
    {...props}
  >
    {children}
  </span>
);
