import CurrencyLogo from '../../components/CurrencyLogo';
import { RowBetween, RowFixed } from '../../components/Row';
import { Field } from '../../state/mint/actions';
import { Currency, CurrencyAmount, Fraction, Percent } from '@beamswap/sdk';
import React from 'react';
import { Button } from '@onbeam/ui';
import { css } from '@onbeam/styled-system/css';

export function ConfirmAddModalBottom({
  noLiquidity,
  price,
  currencies,
  parsedAmounts,
  poolTokenPercentage,
  onAdd,
}: {
  noLiquidity?: boolean;
  price?: Fraction;
  currencies: { [field in Field]?: Currency };
  parsedAmounts: { [field in Field]?: CurrencyAmount };
  poolTokenPercentage?: Percent;
  onAdd: () => void;
}) {
  return (
    <>
      <RowBetween>
        <p>{currencies[Field.CURRENCY_A]?.symbol} deposited</p>
        <RowFixed>
          <CurrencyLogo
            currency={currencies[Field.CURRENCY_A]}
            style={{ marginRight: '8px' }}
          />
          <p>{parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}</p>
        </RowFixed>
      </RowBetween>
      <RowBetween>
        <p>{currencies[Field.CURRENCY_B]?.symbol} deposited</p>
        <RowFixed>
          <CurrencyLogo
            currency={currencies[Field.CURRENCY_B]}
            style={{ marginRight: '8px' }}
          />
          <p>{parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}</p>
        </RowFixed>
      </RowBetween>
      <RowBetween>
        <p>rates</p>
        <p>
          {`1 ${currencies[Field.CURRENCY_A]?.symbol} = ${price?.toSignificant(
            4,
          )} ${currencies[Field.CURRENCY_B]?.symbol}`}
        </p>
      </RowBetween>
      <RowBetween style={{ justifyContent: 'flex-end' }}>
        <p>
          {`1 ${
            currencies[Field.CURRENCY_B]?.symbol
          } = ${price?.invert().toSignificant(4)} ${
            currencies[Field.CURRENCY_A]?.symbol
          }`}
        </p>
      </RowBetween>
      <RowBetween>
        <p>share of pool:</p>
        <p>{noLiquidity ? '100' : poolTokenPercentage?.toSignificant(4)}%</p>
      </RowBetween>
      <Button size="lg" className={css({ mt: '5' })} onClick={onAdd}>
        {noLiquidity ? 'create pool & supply' : 'confirm supply'}
      </Button>
    </>
  );
}
