import { AutoColumn, ColumnCenter } from '../../components/Column';
import { ConnectButton } from '../../components/ConnectButton';
import CurrencyInputPanel from '../../components/CurrencyInputPanel';
import DoubleCurrencyLogo from '../../components/DoubleLogo';
import { AddRemoveTabs } from '../../components/NavigationTabs';
import { MinimalPositionCard } from '../../components/PositionCard';
import Row, { RowFlat } from '../../components/Row';
import TransactionConfirmationModal from '../../components/TransactionConfirmationModal';
import { ROUTER_ADDRESS } from '../../constants';
import { PairState } from '../../data/Reserves';
import { useActiveWeb3React } from '../../hooks';
import { useCurrency } from '../../hooks/Tokens';
import {
  ApprovalState,
  useApproveCallback,
} from '../../hooks/useApproveCallback';
import { Field } from '../../state/mint/actions';
import {
  useDerivedMintInfo,
  useMintActionHandlers,
  useMintState,
} from '../../state/mint/hooks';
import { useTransactionAdder } from '../../state/transactions/hooks';
import {
  useIsExpertMode,
  useUserDeadline,
  useUserSlippageTolerance,
} from '../../state/user/hooks';
import {
  calculateGasMargin,
  calculateSlippageAmount,
  getRouterContract,
} from '../../utils';
import { currencyId } from '../../utils/currencyId';
import { maxAmountSpend } from '../../utils/maxAmountSpend';
import { wrappedCurrency } from '../../utils/wrappedCurrency';
import { Wrapper } from '../Pool/styleds';
import { ConfirmAddModalBottom } from './ConfirmAddModalBottom';
import { PoolPriceBar } from './PoolPriceBar';
import {
  Currency,
  currencyEquals,
  DEV,
  TokenAmount,
  WDEV,
} from '@beamswap/sdk';
import { BigNumber } from '@ethersproject/bignumber';
import { TransactionResponse } from '@ethersproject/providers';
import { useCallback, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useAccount } from 'wagmi';
import useConnectedChain from '../../hooks/useConnectedChain';
import { ContractFunction } from 'ethers';
import { ConnectHandler } from '../../layout/ConnectHandler';
import {
  Button,
  IconButton,
  Modal,
  PixelBackground,
  TransactionDetailsBox,
} from '@onbeam/ui';
import { PlusIcon } from '@onbeam/icons';
import { css } from '@onbeam/styled-system/css';
import { hstack, text } from '@onbeam/styled-system/patterns';

export default function AddLiquidity({
  match: {
    params: { currencyIdA, currencyIdB },
  },
  history,
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
  const { library } = useActiveWeb3React();
  const { chainId, isSupported } = useConnectedChain();
  const { address } = useAccount();

  const currencyA = useCurrency(currencyIdA);
  const currencyB = useCurrency(currencyIdB);

  const oneCurrencyIsWDEV = Boolean(
    chainId &&
      ((currencyA && currencyEquals(currencyA, WDEV[chainId])) ||
        (currencyB && currencyEquals(currencyB, WDEV[chainId]))),
  );

  const expertMode = useIsExpertMode();

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState();
  const {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error,
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined);
  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity);

  const isValid = !error;

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false); // clicked confirm

  // txn values
  const [deadline] = useUserDeadline(); // custom from users settings
  const [allowedSlippage] = useUserSlippageTolerance(); // custom from users
  const [txHash, setTxHash] = useState<string>('');

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: noLiquidity
      ? otherTypedValue
      : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  };

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: TokenAmount } = [
    Field.CURRENCY_A,
    Field.CURRENCY_B,
  ].reduce((accumulator, field) => {
    return {
      ...accumulator,
      [field]: maxAmountSpend(currencyBalances[field]),
    };
  }, {});

  // check whether the user has approved the router on the tokens
  const [approvalA, approveACallback] = useApproveCallback(
    parsedAmounts[Field.CURRENCY_A],
    ROUTER_ADDRESS[chainId ? chainId : ''],
  );
  const [approvalB, approveBCallback] = useApproveCallback(
    parsedAmounts[Field.CURRENCY_B],
    ROUTER_ADDRESS[chainId ? chainId : ''],
  );

  const addTransaction = useTransactionAdder();

  async function onAdd() {
    if (!chainId || !library || !address) return;
    const router = getRouterContract(chainId, library, address);

    const {
      [Field.CURRENCY_A]: parsedAmountA,
      [Field.CURRENCY_B]: parsedAmountB,
    } = parsedAmounts;
    if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB) {
      return;
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(
        parsedAmountA,
        noLiquidity ? 0 : allowedSlippage,
      )[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(
        parsedAmountB,
        noLiquidity ? 0 : allowedSlippage,
      )[0],
    };

    const deadlineFromNow = Math.ceil(Date.now() / 1000) + deadline;

    let estimate: ContractFunction<BigNumber>;
    let method: (...args: any) => Promise<TransactionResponse>;
    let args: Array<string | string[] | number>;
    let value: BigNumber | null;
    if (currencyA === DEV || currencyB === DEV) {
      const tokenBIsETH = currencyB === DEV;
      estimate = router.estimateGas.addLiquidityETH;
      method = router.addLiquidityETH;
      args = [
        wrappedCurrency(tokenBIsETH ? currencyA : currencyB, chainId)
          ?.address ?? '', // token
        (tokenBIsETH ? parsedAmountA : parsedAmountB).raw.toString(), // token desired
        amountsMin[
          tokenBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B
        ].toString(), // token min
        amountsMin[
          tokenBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A
        ].toString(), // DEV min
        address,
        deadlineFromNow,
      ];
      value = BigNumber.from(
        (tokenBIsETH ? parsedAmountB : parsedAmountA).raw.toString(),
      );
    } else {
      estimate = router.estimateGas.addLiquidity;
      method = router.addLiquidity;
      args = [
        wrappedCurrency(currencyA, chainId)?.address ?? '',
        wrappedCurrency(currencyB, chainId)?.address ?? '',
        parsedAmountA.raw.toString(),
        parsedAmountB.raw.toString(),
        amountsMin[Field.CURRENCY_A].toString(),
        amountsMin[Field.CURRENCY_B].toString(),
        address,
        deadlineFromNow,
      ];
      value = null;
    }

    setAttemptingTxn(true);
    await estimate(...args, value ? { value } : {})
      .then((estimatedGasLimit) =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit),
        }).then((response) => {
          setAttemptingTxn(false);

          addTransaction(response, {
            summary: `Add ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(3)} ${currencies[Field.CURRENCY_A]?.symbol} and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(3)} ${currencies[Field.CURRENCY_B]?.symbol}`,
          });

          setTxHash(response.hash);
        }),
      )
      .catch((error) => {
        setAttemptingTxn(false);
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error);
        }
      });
  }

  const modalHeader = () => {
    return noLiquidity ? (
      <AutoColumn $gap="20px">
        <RowFlat>
          <p className={text({ style: 'xl', mr: '2' })}>
            {`${currencies[Field.CURRENCY_A]?.symbol}/${currencies[Field.CURRENCY_B]?.symbol}`}
          </p>
          <DoubleCurrencyLogo
            currency0={currencies[Field.CURRENCY_A]}
            currency1={currencies[Field.CURRENCY_B]}
            size={30}
          />
        </RowFlat>
      </AutoColumn>
    ) : (
      <AutoColumn $gap="20px">
        <RowFlat style={{ marginTop: '20px' }}>
          <p className={text({ style: 'xl', mr: '2' })}>
            {liquidityMinted?.toSignificant(6)}
          </p>
          <DoubleCurrencyLogo
            currency0={currencies[Field.CURRENCY_A]}
            currency1={currencies[Field.CURRENCY_B]}
            size={30}
          />
        </RowFlat>
        <Row>
          <p className={text({ style: 'xl' })}>
            {`${currencies[Field.CURRENCY_A]?.symbol}/${currencies[Field.CURRENCY_B]?.symbol} pool tokens`}
          </p>
        </Row>
        <p className={css({ pt: '2' })}>
          {`output is estimated. If the price changes by more than ${
            allowedSlippage / 100
          }% your transaction will revert.`}
        </p>
      </AutoColumn>
    );
  };

  const modalBottom = () => {
    return (
      <ConfirmAddModalBottom
        price={price}
        currencies={currencies}
        parsedAmounts={parsedAmounts}
        noLiquidity={noLiquidity}
        onAdd={onAdd}
        poolTokenPercentage={poolTokenPercentage}
      />
    );
  };

  const pendingText = `supplying ${parsedAmounts[
    Field.CURRENCY_A
  ]?.toSignificant(6)} ${
    currencies[Field.CURRENCY_A]?.symbol
  } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${
    currencies[Field.CURRENCY_B]?.symbol
  }`;

  const handleCurrencyASelect = useCallback(
    (currencyA: Currency) => {
      const newCurrencyIdA = currencyId(currencyA);
      if (newCurrencyIdA === currencyIdB) {
        history.push(`/add/${currencyIdB}/${currencyIdA}`);
      } else {
        history.push(`/add/${newCurrencyIdA}/${currencyIdB}`);
      }
    },
    [currencyIdB, history, currencyIdA],
  );
  const handleCurrencyBSelect = useCallback(
    (currencyB: Currency) => {
      const newCurrencyIdB = currencyId(currencyB);
      if (currencyIdA === newCurrencyIdB) {
        if (currencyIdB) {
          history.push(`/add/${currencyIdB}/${newCurrencyIdB}`);
        } else {
          history.push(`/add/${newCurrencyIdB}`);
        }
      } else {
        history.push(
          `/add/${currencyIdA ? currencyIdA : 'ETH'}/${newCurrencyIdB}`,
        );
      }
    },
    [currencyIdA, history, currencyIdB],
  );

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false);
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput('');
    }
    setTxHash('');
  }, [onFieldAInput, txHash]);

  return (
    <ConnectHandler>
      <AddRemoveTabs adding={true} />
      <Wrapper>
        <TransactionConfirmationModal
          isOpen={showConfirm}
          onDismiss={handleDismissConfirmation}
          attemptingTxn={attemptingTxn}
          hash={txHash}
          content={() => (
            <Modal
              open={showConfirm}
              onOpenChange={handleDismissConfirmation}
              title={
                noLiquidity ? 'you are creating a pool' : 'you will receive'
              }
            >
              <div className={css({ mb: '4' })}>
                <TransactionDetailsBox>{modalHeader()}</TransactionDetailsBox>
              </div>
              {modalBottom()}
            </Modal>
          )}
          pendingText={pendingText}
        />
        <AutoColumn $gap="20px">
          {noLiquidity && (
            <ColumnCenter>
              <TransactionDetailsBox>
                <AutoColumn $gap="0px">
                  <p className={text({ style: 'sm', color: 'purple.200' })}>
                    you are the first liquidity provider.
                  </p>
                  <p className={text({ style: 'sm', color: 'purple.200' })}>
                    the ratio of tokens you add will set the price of this pool.
                  </p>
                  <p className={text({ style: 'sm', color: 'purple.200' })}>
                    once you are happy with the rate click supply to review.
                  </p>
                </AutoColumn>
              </TransactionDetailsBox>
            </ColumnCenter>
          )}
          <CurrencyInputPanel
            value={formattedAmounts[Field.CURRENCY_A]}
            onUserInput={onFieldAInput}
            onMax={() => {
              onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '');
            }}
            onCurrencySelect={handleCurrencyASelect}
            currency={currencies[Field.CURRENCY_A]}
            id="add-liquidity-input-tokena"
            showCommonBases
          />
          <ColumnCenter>
            <IconButton className={css({ pointerEvents: 'none' })}>
              <PlusIcon />
            </IconButton>
          </ColumnCenter>
          <CurrencyInputPanel
            value={formattedAmounts[Field.CURRENCY_B]}
            onUserInput={onFieldBInput}
            onCurrencySelect={handleCurrencyBSelect}
            onMax={() => {
              onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '');
            }}
            currency={currencies[Field.CURRENCY_B]}
            id="add-liquidity-input-tokenb"
            showCommonBases
          />
          {currencies[Field.CURRENCY_A] &&
            currencies[Field.CURRENCY_B] &&
            pairState !== PairState.INVALID && (
              <div>
                <p className={text({ color: '[#7F7F7F]', mb: '2' })}>
                  {noLiquidity ? 'initial prices' : 'prices'} and pool share
                </p>
                <PixelBackground>
                  <PoolPriceBar
                    currencies={currencies}
                    poolTokenPercentage={poolTokenPercentage}
                    noLiquidity={noLiquidity}
                    price={price}
                  />
                </PixelBackground>
              </div>
            )}

          {!address || !isSupported ? (
            <ConnectButton />
          ) : (
            <AutoColumn $gap={'md'}>
              {(approvalA === ApprovalState.NOT_APPROVED ||
                approvalA === ApprovalState.PENDING ||
                approvalB === ApprovalState.NOT_APPROVED ||
                approvalB === ApprovalState.PENDING) &&
                isValid && (
                  <div className={hstack({ align: 'center', gap: '2' })}>
                    {approvalA !== ApprovalState.APPROVED && (
                      <Button
                        onClick={approveACallback}
                        isLoading={approvalA === ApprovalState.PENDING}
                        size="lg"
                        loadingMessage={`approving ${currencies[Field.CURRENCY_A]?.symbol}`}
                      >
                        approve {currencies[Field.CURRENCY_A]?.symbol}
                      </Button>
                    )}
                    {approvalB !== ApprovalState.APPROVED && (
                      <Button
                        onClick={approveBCallback}
                        isLoading={approvalB === ApprovalState.PENDING}
                        size="lg"
                        loadingMessage={`approving ${currencies[Field.CURRENCY_B]?.symbol}`}
                      >
                        approve {currencies[Field.CURRENCY_B]?.symbol}
                      </Button>
                    )}
                  </div>
                )}
              <Button
                onClick={() => {
                  expertMode ? onAdd() : setShowConfirm(true);
                }}
                size="lg"
                disabled={
                  !isValid ||
                  approvalA !== ApprovalState.APPROVED ||
                  approvalB !== ApprovalState.APPROVED
                }
                // error={
                //   !isValid &&
                //   !!parsedAmounts[Field.CURRENCY_A] &&
                //   !!parsedAmounts[Field.CURRENCY_B]
                // }
              >
                {error ?? 'supply'}
              </Button>
            </AutoColumn>
          )}
        </AutoColumn>

        {pair && !noLiquidity && pairState !== PairState.INVALID ? (
          <AutoColumn style={{ minWidth: '20rem', marginTop: '1rem' }}>
            <MinimalPositionCard
              showUnwrapped={oneCurrencyIsWDEV}
              pair={pair}
            />
          </AutoColumn>
        ) : null}
      </Wrapper>
    </ConnectHandler>
  );
}
