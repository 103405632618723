import { ONE_BIPS } from '../../constants';
import { Field } from '../../state/mint/actions';
import { Currency, Percent, Price } from '@beamswap/sdk';
import { hstack, text } from '@onbeam/styled-system/patterns';

export function PoolPriceBar({
  currencies,
  noLiquidity,
  poolTokenPercentage,
  price,
}: {
  currencies: { [field in Field]?: Currency };
  noLiquidity?: boolean;
  poolTokenPercentage?: Percent;
  price?: Price;
}) {
  return (
    <div
      className={hstack({
        align: 'center',
        justify: 'space-around',
        gap: '3',
        textAlign: 'center',
      })}
    >
      <div>
        <p>{price?.toSignificant(6) ?? '-'}</p>
        <p>
          {currencies[Field.CURRENCY_B]?.symbol} per{' '}
          {currencies[Field.CURRENCY_A]?.symbol}
        </p>
      </div>

      <div>
        <p>{price?.invert()?.toSignificant(6) ?? '-'}</p>
        <p>
          {currencies[Field.CURRENCY_A]?.symbol} per{' '}
          {currencies[Field.CURRENCY_B]?.symbol}
        </p>
      </div>

      <div>
        <p>
          {noLiquidity && price
            ? '100'
            : (poolTokenPercentage?.lessThan(ONE_BIPS)
                ? '<0.01'
                : poolTokenPercentage?.toFixed(2)) ?? '0'}
          %
        </p>
        <p className={text({ pt: '1' })}>Share of Pool</p>
      </div>
    </div>
  );
}
