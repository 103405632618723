import { PoweredByBeamBanner } from '@onbeam/ui';

import React from 'react';

interface Props {
  className?: string;
}

export const Footer = ({ className }: Props) => {
  return (
    <div className={className}>
      <PoweredByBeamBanner />
    </div>
  );
};
