import EthereumLogo from '../../assets/images/ethereum-logo.png';
import { useAccount } from 'wagmi';
import { useCurrencyBalances } from '../../state/wallet/hooks';
import { Currency, CurrencyAmount, Token } from '@beamswap/sdk';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { SelectModal, SelectModalItem } from '@onbeam/ui';
import { useAllTokens } from '../../hooks/Tokens';
import CurrencyLogo from '../CurrencyLogo';
import { WrappedTokenInfo } from '../../state/lists/hooks';
import { useToken } from '../../hooks/Tokens';
import {
  useAddUserToken,
  useRemoveUserAddedToken,
  useUserAddedTokens,
} from '../../state/user/hooks';
import useConnectedChain from '../../hooks/useConnectedChain';

interface CurrencyModalProps {
  onCurrencySelect?: (currency: Currency) => void;
  currency?: Currency | WrappedTokenInfo;
}

const devItem: SelectModalItem = {
  ...Currency.DEV,
  id: Currency.DEV.symbol || 'BEAM',
  name: Currency.DEV.symbol || 'BEAM',
  subname: Currency.DEV.name,
  logo: <img src={EthereumLogo} width={24} height={24} alt="" />,
};

const SPAM_TOKENS = [
  '0x5bCA1699E29f2043DD2bbb5c506d2A8D1Fa84Fa7',
  '0xb8E72C4811C16dE14403FfC6dfF2c6ce63fc42D6',
  '0xb0eda892b0826f3b7042Be793Bd239D5C81d18Bc',
  '0xb3A4FE68e87dAd5aE9ee384A4E788b5816dB0047',
].map((address) => address.toLowerCase());

const findBalance = (currency: Currency, currencyAmount?: CurrencyAmount) => {
  if (currencyAmount?.currency instanceof Token && currency instanceof Token) {
    return currencyAmount.currency.address === currency.address;
  }
  return currencyAmount?.currency.symbol === currency.symbol;
};

export const CurrencyModal = ({
  currency,
  onCurrencySelect,
}: CurrencyModalProps) => {
  const { chainId } = useConnectedChain();
  const [selectedTokenId, setSelectedTokenId] = useState<string | undefined>();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { address } = useAccount();
  const allTokens = useAllTokens();
  const customToken = useToken(searchQuery);
  const balances = useCurrencyBalances(address, [
    Currency.DEV,
    ...Object.values(allTokens),
    customToken ?? undefined,
  ]);
  const removeToken = useRemoveUserAddedToken();
  const addToken = useAddUserToken();
  const userAddedTokens = useUserAddedTokens();

  const isUserAddedToken = useCallback(
    (token: Token) => userAddedTokens.find((t) => t.address === token.address),
    [userAddedTokens],
  );

  // Maybe we should use tokenItems directly and do all searching/filtering in here
  // instead of calling setItems each time. Current situation can cause inconsistencies
  const items = useMemo<SelectModalItem[]>(
    () => [
      {
        ...devItem,
        // TODO: usd balance
        // detailCaption: 'TODO: usd balance',
        detailLabel:
          balances
            .find((b) => findBalance(Currency.DEV, b))
            ?.toSignificant(4) || '-',
      },
      ...Object.values(allTokens)
        .map((token): SelectModalItem => {
          const balance =
            balances.find((b) => findBalance(token, b))?.toSignificant(4) ||
            '-';

          return {
            id: token.address,
            name: token.symbol || '',
            subname: token.name,
            address: token.address,
            // TODO: usd balance
            detailLabel: balance,
            logo: <CurrencyLogo currency={token} size="24px" />,
            action:
              isUserAddedToken(token) && selectedTokenId !== token.address
                ? {
                    title: 'added by user',
                    buttonLabel: 'remove',
                    onClick: () => removeToken(chainId!, token.address),
                  }
                : undefined,
          };
        })
        .filter(Boolean),
      ...(customToken &&
      !isUserAddedToken(customToken) &&
      !allTokens[customToken.address]
        ? [
            {
              id: customToken.address,
              name: customToken.symbol || '',
              subname: customToken.name,
              address: customToken.address,
              // TODO: usd balance
              detailLabel:
                balances
                  .find((b) => findBalance(customToken, b))
                  ?.toSignificant(4) || '-',
              logo: <CurrencyLogo currency={customToken} size="24px" />,
              action: {
                title: 'found by address',
                buttonLabel: 'add',
                onClick: () => addToken(customToken),
              },
            },
          ]
        : []),
    ],
    [
      balances,
      allTokens,
      customToken,
      isUserAddedToken,
      selectedTokenId,
      addToken,
      removeToken,
      chainId,
    ],
  );

  const sort = useCallback(
    (a: SelectModalItem, b: SelectModalItem) => {
      // Always sort active token first
      if (selectedTokenId) {
        if (a.id === selectedTokenId) return -1;
        if (b.id === selectedTokenId) return 1;
      }

      // Alway sort beam to first place
      if (a.id === Currency.DEV.symbol) return -1;
      if (b.id === Currency.DEV.symbol) return 1;

      // Sort by balance
      // TODO: Sort by USD value of balance
      if (a.detailLabel && b.detailLabel) {
        if (a.detailLabel > b.detailLabel) return -1;
        if (a.detailLabel < b.detailLabel) return 1;
      }

      // Sort by name
      if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
      return 0;
    },
    [selectedTokenId],
  );

  const filter = useCallback(
    (item: SelectModalItem) => {
      const search = searchQuery.toLowerCase();

      if (!search) return true;

      if (item.address && SPAM_TOKENS.includes(item.address.toLowerCase())) {
        return false;
      }

      const matchName = item.name?.toLowerCase().includes(search);
      const matchAddress = item.address?.toLowerCase() === search;
      return matchName || matchAddress;
    },
    [searchQuery],
  );

  const handleSelect = useCallback(
    (id?: string) => {
      setSelectedTokenId(id);
      if (!id) return;

      if (id === customToken?.address) {
        if (!allTokens[customToken.address]) {
          addToken(customToken as Token);
        }
        onCurrencySelect?.(customToken);
        return;
      }

      const selectedCurrency =
        id === Currency.DEV.symbol ? Currency.DEV : allTokens[id];

      if (!selectedCurrency) return;
      onCurrencySelect?.(selectedCurrency);
    },
    [onCurrencySelect, allTokens, addToken, customToken],
  );

  useEffect(() => {
    if (!currency) return setSelectedTokenId(undefined);

    if (currency instanceof Token) {
      return setSelectedTokenId(currency.address);
    }

    setSelectedTokenId(Currency.DEV.symbol);
  }, [currency]);

  const reset = () => {
    setSearchQuery('');
  };

  return (
    <SelectModal
      selectedId={selectedTokenId}
      onChange={handleSelect}
      searchQuery={searchQuery}
      onSearch={setSearchQuery}
      onOpenChange={(isOpen) => {
        if (isOpen) reset();
      }}
      title="select a token"
      searchPlaceholder="search name or paste address"
      listTitle="token name"
      sort={sort}
      filter={filter}
      items={items}
    />
  );
};
