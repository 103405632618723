import { Price } from '@beamswap/sdk';
import { TransactionDetailsRow } from '@onbeam/ui';
import { Repeat } from 'react-feather';
import { useTranslation } from 'react-i18next';

interface TradePriceProps {
  price?: Price;
  showInverted: boolean;
  setShowInverted: (showInverted: boolean) => void;
}

export const TradePriceRow = ({
  price,
  showInverted,
  setShowInverted,
}: TradePriceProps) => {
  const { t } = useTranslation();

  const formattedPrice = showInverted
    ? price?.toSignificant(6)
    : price?.invert()?.toSignificant(6);

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency);
  const label = showInverted
    ? `${price?.quoteCurrency?.symbol?.toUpperCase()} per ${price?.baseCurrency?.symbol?.toUpperCase()}`
    : `${price?.baseCurrency?.symbol?.toUpperCase()} per ${price?.quoteCurrency?.symbol?.toUpperCase()}`;

  return (
    <TransactionDetailsRow
      label={t('price')}
      details={
        show
          ? {
              label: `${formattedPrice ?? '-'} ${label}`,
              icon: <Repeat size={12} />,
              onClick: () => setShowInverted(!showInverted),
            }
          : undefined
      }
    />
  );
};
