import {
  Divider,
  TransactionDetailsBox,
  TransactionDetailsRow,
} from '@onbeam/ui';
import { Field } from '../../state/swap/actions';
import { useUserSlippageTolerance } from '../../state/user/hooks';
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
} from '../../utils/prices';
import SwapRoute from './SwapRoute';
import { Trade, TradeType } from '@beamswap/sdk';
import React from 'react';
import { formattedPriceImpact } from './formattedPriceImpact';

function TradeSummary({
  trade,
  allowedSlippage,
}: {
  trade: Trade;
  allowedSlippage: number;
}) {
  const { priceImpactWithoutFee, realizedLPFee } =
    computeTradePriceBreakdown(trade);
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT;
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(
    trade,
    allowedSlippage,
  );

  return (
    <>
      <TransactionDetailsRow
        label={isExactIn ? 'minimum received' : 'maximum sold'}
        tooltipLabel="your transaction will revert if there is a large, unfavorable price movement before it is confirmed."
        details={{
          label: isExactIn
            ? slippageAdjustedAmounts[Field.OUTPUT]
              ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)} ${
                  trade.outputAmount.currency.symbol
                }`
              : '-'
            : slippageAdjustedAmounts[Field.INPUT]
              ? `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)} ${
                  trade.inputAmount.currency.symbol
                }`
              : '-',
        }}
      />

      <TransactionDetailsRow
        label="price impact"
        tooltipLabel="the difference between the market price and estimated price due to trade size."
        details={{
          label: formattedPriceImpact(priceImpactWithoutFee),
        }}
      />

      <TransactionDetailsRow
        label="liquidity provider fee"
        tooltipLabel="a portion of each trade (0.30%) goes to liquidity providers as a protocol incentive."
        details={{
          label: realizedLPFee
            ? `${realizedLPFee.toSignificant(4)} ${
                trade.inputAmount.currency.symbol
              }`
            : '-',
        }}
      />
    </>
  );
}

export interface AdvancedSwapDetailsProps {
  trade?: Trade;
  children?: React.ReactNode;
}

export function AdvancedSwapDetails({
  trade,
  children,
}: AdvancedSwapDetailsProps) {
  const [allowedSlippage] = useUserSlippageTolerance();

  const showRoute = Boolean(trade && trade.route.path.length > 2);

  return (
    <TransactionDetailsBox>
      {children}

      {trade && (
        <>
          <Divider />

          <TradeSummary trade={trade} allowedSlippage={allowedSlippage} />
          {showRoute && (
            <>
              <Divider />
              <TransactionDetailsRow
                label="route"
                tooltipLabel="routing through these tokens resulted in the best price for your trade."
              />

              <SwapRoute trade={trade} />
            </>
          )}
        </>
      )}
    </TransactionDetailsBox>
  );
}
