import { useAccount } from 'wagmi';
import { useTotalSupply } from '../../data/TotalSupply';
import { useTokenBalance } from '../../state/wallet/hooks';
import { currencyId } from '../../utils/currencyId';
import { unwrappedToken } from '../../utils/wrappedCurrency';
import CurrencyLogo from '../CurrencyLogo';
import DoubleCurrencyLogo from '../DoubleLogo';
import { JSBI, Pair, Percent } from '@beamswap/sdk';
import { useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import {
  Button,
  Divider,
  TransactionDetailsBox,
  TransactionDetailsRow,
} from '@onbeam/ui';
import { hstack, text } from '@onbeam/styled-system/patterns';
import { css } from '@onbeam/styled-system/css';
import { DownIcon, UpIcon } from '@onbeam/icons';
import { Dots } from '../Dots';

interface PositionCardProps {
  pair: Pair;
  showUnwrapped?: boolean;
  border?: string;
}

export function MinimalPositionCard({
  pair,
  showUnwrapped = false,
}: PositionCardProps) {
  const { address } = useAccount();

  const currency0 = showUnwrapped ? pair.token0 : unwrappedToken(pair.token0);
  const currency1 = showUnwrapped ? pair.token1 : unwrappedToken(pair.token1);

  const userPoolBalance = useTokenBalance(
    address ?? undefined,
    pair.liquidityToken,
  );
  const totalPoolTokens = useTotalSupply(pair.liquidityToken);

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(
            pair.token0,
            totalPoolTokens,
            userPoolBalance,
            false,
          ),
          pair.getLiquidityValue(
            pair.token1,
            totalPoolTokens,
            userPoolBalance,
            false,
          ),
        ]
      : [undefined, undefined];

  if (!userPoolBalance) return null;

  return (
    <TransactionDetailsBox>
      <p className={text({ style: 'caption' })}>your position</p>
      <Divider />
      <TransactionDetailsRow
        label={`${currency0.symbol}/${currency1.symbol}`}
        details={{
          label: userPoolBalance?.toSignificant(4) || '-',
          icon: (
            <DoubleCurrencyLogo
              currency0={currency0}
              currency1={currency1}
              size={16}
            />
          ),
        }}
      />
      <TransactionDetailsRow
        label={`${currency0.symbol}`}
        details={{
          label: token0Deposited?.toSignificant(6) || '-',
          icon: <CurrencyLogo size="16px" currency={currency0} />,
        }}
      />
      <TransactionDetailsRow
        label={`${currency1.symbol}`}
        details={{
          label: token1Deposited?.toSignificant(6) || '-',
          icon: <CurrencyLogo size="16px" currency={currency1} />,
        }}
      />
    </TransactionDetailsBox>
  );
}

export default function FullPositionCard({ pair }: PositionCardProps) {
  const { address } = useAccount();

  const currency0 = unwrappedToken(pair.token0);
  const currency1 = unwrappedToken(pair.token1);

  const [showMore, setShowMore] = useState(false);

  const userPoolBalance = useTokenBalance(
    address ?? undefined,
    pair.liquidityToken,
  );
  const totalPoolTokens = useTotalSupply(pair.liquidityToken);

  const poolTokenPercentage =
    !!userPoolBalance &&
    !!totalPoolTokens &&
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined;

  const [token0Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(
            pair.token0,
            totalPoolTokens,
            userPoolBalance,
            false,
          ),
          pair.getLiquidityValue(
            pair.token1,
            totalPoolTokens,
            userPoolBalance,
            false,
          ),
        ]
      : [undefined, undefined];

  return (
    <>
      <button
        type="button"
        className={hstack({
          align: 'center',
          gap: '2',
          justify: 'space-between',
        })}
        onClick={() => setShowMore((prev) => !prev)}
        style={{ cursor: 'pointer' }}
      >
        <div
          className={hstack({
            align: 'center',
            gap: '2',
            justify: 'flex-start',
          })}
        >
          <DoubleCurrencyLogo
            currency0={currency0}
            currency1={currency1}
            margin={true}
            size={20}
          />
          <p>
            {!currency0 || !currency1 ? (
              <Dots>loading</Dots>
            ) : (
              `${currency0.symbol} / ${currency1.symbol}`
            )}
          </p>
        </div>
        {showMore ? <UpIcon /> : <DownIcon />}
      </button>
      {showMore && (
        <>
          <Divider />
          <TransactionDetailsRow
            label={`pooled ${currency0.symbol}:`}
            details={{
              label: token0Deposited?.toSignificant(6) || '-',
              icon: <CurrencyLogo size="16px" currency={currency0} />,
            }}
          />

          <TransactionDetailsRow
            label="your pool tokens:"
            details={{
              label: userPoolBalance?.toSignificant(4) || '-',
            }}
          />

          <TransactionDetailsRow
            label="your pool share:"
            details={{
              label: poolTokenPercentage
                ? `${poolTokenPercentage.toFixed(2)}%`
                : '-',
            }}
          />

          {/* TODO: Implement when ready */}
          {/* <div className={css({ my: '3', textAlign: 'center' })}>
            <Link>View pool information (coming soon) ↗</Link>
          </div> */}

          <div className={hstack({ align: 'center', gap: '2', mt: '3' })}>
            <Button
              size="sm"
              as={ReactLink}
              to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}
              className={css({ flex: '1' })}
            >
              add
            </Button>
            <Button
              size="sm"
              as={ReactLink}
              to={`/remove/${currencyId(currency0)}/${currencyId(currency1)}`}
              className={css({ flex: '1' })}
            >
              remove
            </Button>
          </div>
        </>
      )}
    </>
  );
}
