import { mdxComponents } from '@onbeam/ui';
import React, { lazy, Suspense } from 'react';
import { importMDX } from 'mdx.macro';

const TermsOfServiceDocument = lazy(() => importMDX('./terms-of-service.mdx'));

export const TermsOfService = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <TermsOfServiceDocument components={mdxComponents} />
  </Suspense>
);
