import { Link } from '@onbeam/ui';
import { footerStyle } from './Footer.styles';
import { text } from '@onbeam/styled-system/patterns';
import { Link as RouterLink } from 'react-router-dom';
import { useGTMStore } from '@onbeam/utils';

export const Footer = () => {
  const open = useGTMStore((store) => store.openConsentModal);

  return (
    <footer className={footerStyle}>
      <Link
        as={RouterLink}
        to="/terms-of-service"
        color="mono.100"
        className={text({ style: 'sm' })}
      >
        terms of service
      </Link>
      <Link
        as={RouterLink}
        to="/privacy-policy"
        color="mono.100"
        className={text({ style: 'sm' })}
      >
        privacy policy
      </Link>
      <Link
        href="https://support.onbeam.com/hc/en-us/categories/14553016517533-Beam-Swap"
        target="_blank"
        rel="noopener noreferrer"
        color="mono.100"
        className={text({ style: 'sm' })}
      >
        support
      </Link>
      <button
        type="button"
        className={text({ style: 'sm', cursor: 'pointer' })}
        onClick={open}
      >
        cookies
      </button>
    </footer>
  );
};
