import {
  Button,
  ErrorMessage,
  TransactionDetailsBox,
  TransactionDetailsRow,
} from '@onbeam/ui';
import { Field } from '../../state/swap/actions';
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  formatExecutionPrice,
  warningSeverity,
} from '../../utils/prices';
import { AutoRow } from '../Row';
import { Trade, TradeType } from '@beamswap/sdk';
import { useMemo, useState } from 'react';
import { Repeat } from 'react-feather';
import { formattedPriceImpact } from './formattedPriceImpact';
import { css } from '@onbeam/styled-system/css';

export default function SwapModalFooter({
  trade,
  onConfirm,
  allowedSlippage,
  swapErrorMessage,
  disabledConfirm,
}: {
  trade: Trade;
  allowedSlippage: number;
  onConfirm: () => void;
  swapErrorMessage: string | undefined;
  disabledConfirm: boolean;
}) {
  const [showInverted, setShowInverted] = useState<boolean>(false);
  const slippageAdjustedAmounts = useMemo(
    () => computeSlippageAdjustedAmounts(trade, allowedSlippage),
    [allowedSlippage, trade],
  );
  const { priceImpactWithoutFee, realizedLPFee } = useMemo(
    () => computeTradePriceBreakdown(trade),
    [trade],
  );
  const severity = warningSeverity(priceImpactWithoutFee);

  return (
    <>
      <TransactionDetailsBox>
        <TransactionDetailsRow
          label="price"
          details={{
            label: formatExecutionPrice(trade, showInverted),
            icon: <Repeat size={12} />,
            onClick: () => setShowInverted(!showInverted),
          }}
        />

        <TransactionDetailsRow
          label={
            trade.tradeType === TradeType.EXACT_INPUT
              ? 'minimum received'
              : 'maximum sold'
          }
          tooltipLabel="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed."
          details={{
            label: `${
              trade.tradeType === TradeType.EXACT_INPUT
                ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? '-'
                : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4) ?? '-'
            } ${
              trade.tradeType === TradeType.EXACT_INPUT
                ? trade.outputAmount.currency.symbol
                : trade.inputAmount.currency.symbol
            }`,
          }}
        />

        <TransactionDetailsRow
          label="price Impact"
          tooltipLabel="The difference between the market price and your price due to trade size."
          details={{
            label: formattedPriceImpact(priceImpactWithoutFee),
          }}
        />

        <TransactionDetailsRow
          label="liquidity provider fee"
          tooltipLabel="A portion of each trade (0.30%) goes to liquidity providers as a protocol incentive."
          details={{
            label: realizedLPFee
              ? `${realizedLPFee?.toSignificant(6)} ${trade.inputAmount.currency.symbol}`
              : '-',
          }}
        />
      </TransactionDetailsBox>

      <AutoRow>
        <Button
          size="lg"
          onClick={onConfirm}
          disabled={disabledConfirm}
          id="confirm-swap-or-send"
          className={css({ mb: '2' })}
        >
          {severity > 2 ? 'swap anyway' : 'confirm swap'}
        </Button>
        {swapErrorMessage ? (
          <ErrorMessage>{swapErrorMessage}</ErrorMessage>
        ) : null}
      </AutoRow>
    </>
  );
}
