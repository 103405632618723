import { useAccount } from 'wagmi';
import { AutoColumn } from '../../components/Column';
import FullPositionCard from '../../components/PositionCard';
import { RowBetween } from '../../components/Row';
import { usePairs } from '../../data/Reserves';
import {
  toV2LiquidityToken,
  useTrackedTokenPairs,
} from '../../state/user/hooks';
import {
  useCurrencyBalances,
  useTokenBalancesWithLoadingIndicator,
} from '../../state/wallet/hooks';
import { Currency, Pair } from '@beamswap/sdk';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectHandler } from '../../layout/ConnectHandler';
import {
  Button,
  Link,
  PixelBackground,
  Tooltip,
  TransactionDetailsBox,
} from '@onbeam/ui';
import { Link as ReactLink } from 'react-router-dom';
import { css } from '@onbeam/styled-system/css';
import { Dots } from '../../components/Dots';
import { useAllTokens } from '../../hooks/Tokens';
import { text } from '@onbeam/styled-system/patterns';

export default function Pool() {
  const { t } = useTranslation();
  const { address } = useAccount();
  const allTokens = useAllTokens();
  useCurrencyBalances(address, [Currency.DEV, ...Object.values(allTokens)]);

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs();
  const tokenPairsWithLiquidityTokens = useMemo(
    () =>
      trackedTokenPairs.map((tokens) => ({
        liquidityToken: toV2LiquidityToken(tokens),
        tokens,
      })),
    [trackedTokenPairs],
  );
  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens],
  );
  const [v2PairsBalances, fetchingV2PairBalances] =
    useTokenBalancesWithLoadingIndicator(address ?? undefined, liquidityTokens);

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0'),
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances],
  );

  const v2Pairs = usePairs(
    liquidityTokensWithBalances.map(({ tokens }) => tokens),
  );
  const v2IsLoading =
    fetchingV2PairBalances ||
    v2Pairs?.length < liquidityTokensWithBalances.length ||
    v2Pairs?.some((V2Pair) => !V2Pair);

  const allV2PairsWithLiquidity = v2Pairs
    .map(([, pair]) => pair)
    .filter((v2Pair): v2Pair is Pair => Boolean(v2Pair));

  return (
    <ConnectHandler>
      <AutoColumn $gap="lg" $justify="center">
        <Button
          size="lg"
          id="join-pool-button"
          as={ReactLink}
          className={css({ p: '4' })}
          to="/add/ETH"
        >
          {t('addLiquidity')}
        </Button>

        <AutoColumn $gap="12px" style={{ width: '100%' }}>
          <RowBetween>
            <p className={text({ style: 'md' })}>{t('yourLiquidity')}</p>
            <Tooltip
              width={230}
              label="when you add liquidity, you are given pool tokens that represent your share. if you don’t see a pool you joined in this list, try importing a pool below."
            />
          </RowBetween>

          {!address ? (
            <PixelBackground className={css({ p: '10' })}>
              <p className={css({ textAlign: 'center' })}>
                {t('connectToViewLiquidity')}
              </p>
            </PixelBackground>
          ) : v2IsLoading ? (
            <PixelBackground className={css({ p: '10' })}>
              <p className={css({ textAlign: 'center' })}>
                <Dots>{t('loading')}</Dots>
              </p>
            </PixelBackground>
          ) : allV2PairsWithLiquidity?.length > 0 ? (
            <>
              {allV2PairsWithLiquidity.map((v2Pair) => (
                <TransactionDetailsBox
                  key={v2Pair.liquidityToken.address}
                  as="div"
                >
                  <FullPositionCard pair={v2Pair} />
                </TransactionDetailsBox>
              ))}
            </>
          ) : (
            <PixelBackground className={css({ p: '10' })}>
              <p className={css({ textAlign: 'center' })}>
                {t('noLiquidityFound')}
              </p>
            </PixelBackground>
          )}

          <p className={text({ style: 'base', py: '2', textAlign: 'center' })}>
            don't see a pool you joined?{' '}
            <Link as={ReactLink} id="import-pool-link" to={'/find'}>
              import it
            </Link>
          </p>
        </AutoColumn>
      </AutoColumn>
    </ConnectHandler>
  );
}
