import { css, cx } from '@onbeam/styled-system/css';
import { flex } from '@onbeam/styled-system/patterns';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

const Column = (
  props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
) => (
  <div
    className={flex({ flexDirection: 'column', justifyContent: 'flex-start' })}
    {...props}
  />
);

export const ColumnCenter = (
  props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
) => (
  <div
    className={flex({
      w: 'full',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    })}
    {...props}
  />
);

export const AutoColumn = ({
  className,
  style,
  $gap,
  $justify,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  $gap?: 'sm' | 'md' | 'lg' | string;
  $justify?:
    | 'stretch'
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'space-between';
}) => (
  <div
    className={cx(
      className,
      css({
        display: 'flex',
        flexDirection: 'column',
      }),
    )}
    style={{
      ...style,
      gap:
        ($gap === 'sm' && '8px') ||
        ($gap === 'md' && '12px') ||
        ($gap === 'lg' && '24px') ||
        $gap,
      justifyContent: $justify,
      alignItems: $justify,
    }}
    {...props}
  />
);

export default Column;
