import { NavigationItem, TopNav } from '@onbeam/ui';
import { navItems } from './Header.data';
import { useLocation, useHistory } from 'react-router-dom';
import { BeamLink } from '../../components/BeamLink';
import { ConnectButton } from '../../components/ConnectButton';
import { Settings } from '../Settings';

export const Header = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  const handleRouteChange = (route: NavigationItem) => {
    if (route.isExternal) {
      window.location.href = route.href;
    } else {
      history.push(route.href);
    }
  };

  return (
    <TopNav
      title="swap"
      activePath={pathname}
      isNavigationItemActive={(item) => item.href === pathname}
      onRouteChange={handleRouteChange}
      navItems={navItems}
      linkComponent={BeamLink}
    >
      <ConnectButton />
      <Settings />
    </TopNav>
  );
};
