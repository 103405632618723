import type { Transport } from 'viem';
import { createConfig, http } from 'wagmi';
import { chains } from './chains';
import { WalletConnectParameters } from 'wagmi/connectors';
import { getConnectors } from '@onbeam/utils';

type transports = Record<(typeof chains)[number]['id'], Transport>;

const transports = chains.reduce((acc: transports, chain) => {
  const key = chain.id as keyof transports;
  acc[key] = http() as transports[keyof transports];
  return acc;
}, {} as transports);

const walletMetadata: WalletConnectParameters['metadata'] = {
  name: 'beam swap',
  description: 'decentralized exchange for tokens on beam',
  url: process.env.REACT_APP_HOSTNAME || '',
  icons: ['/favicon.ico'],
};

const connectors = getConnectors({
  projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID!,
  appName: walletMetadata.name,
  appDescription: walletMetadata.description,
  appUrl: walletMetadata.url,
  appIcon: walletMetadata.icons[0],
  walletConnectParameters: { metadata: walletMetadata },
});

export const wagmiConfig = createConfig({
  chains,
  transports,
  connectors,
  ssr: true,
});
