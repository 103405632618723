import { ChainId, ClientConfig } from '@onbeam/sdk';

const environment = process.env.REACT_APP_BEAM_ENVIRONMENT;

const beamConfig: ClientConfig = {
  chainId:
    environment === 'preview' ? ChainId.BEAM_TESTNET : ChainId.BEAM_MAINNET,
  chains: [
    {
      id: ChainId.BEAM_TESTNET,
      publishableKey: process.env.REACT_APP_BEAM_TESTNET_PUBLISHABLE_KEY ?? '',
      isPreview: environment === 'preview',
      sponsor: true,
    },
    {
      id: ChainId.BEAM_MAINNET,
      publishableKey: process.env.REACT_APP_BEAM_MAINNET_PUBLISHABLE_KEY ?? '',
      sponsor: true,
    },
  ],
  debug: environment === 'preview',
};

export { beamConfig };
