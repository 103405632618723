import { Toaster } from '@onbeam/ui';
import { useActivePopups } from '../../state/application/hooks';
import PopupItem from './PopupItem';
import React from 'react';

export default function Popups() {
  // get all popups
  const activePopups = useActivePopups();

  return (
    <>
      <Toaster />
      {activePopups.map((item) => (
        <PopupItem
          key={item.key}
          content={item.content}
          removeAfterMs={item.removeAfterMs}
        />
      ))}
    </>
  );
}
