import { ChainId } from '@beamswap/sdk';
import { useAccount, useConfig } from 'wagmi';

const useConnectedChain = () => {
  const { chain } = useAccount();
  const { chains } = useConfig();

  return {
    chainId: chain?.id as ChainId | undefined,
    isSupported: !!chains?.find((chain) => chain.id === chain?.id),
  };
};

export default useConnectedChain;
