import { PopupContent } from '../../state/application/actions';
import ListUpdatePopup from './ListUpdatePopup';
import TransactionPopup from './TransactionPopup';
import React, { ReactNode } from 'react';

export default function PopupItem({
  removeAfterMs,
  content,
}: {
  removeAfterMs: number | null;
  content: PopupContent;
}) {
  let popupContent: ReactNode;

  if ('txn' in content) {
    const {
      txn: { hash, success, summary },
    } = content;
    popupContent = (
      <TransactionPopup
        hash={hash}
        success={success}
        summary={summary}
        removeAfterMs={removeAfterMs}
      />
    );
  } else if ('listUpdate' in content) {
    const {
      listUpdate: { listUrl, oldList, newList, auto },
    } = content;
    popupContent = (
      <ListUpdatePopup
        listUrl={listUrl}
        oldList={oldList}
        newList={newList}
        auto={auto}
        removeAfterMs={removeAfterMs}
      />
    );
  }

  return popupContent;
}
