import { Link, toast } from '@onbeam/ui';
import useConnectedChain from '../../hooks/useConnectedChain';
import { getEtherscanLink } from '../../utils';

import React, { useEffect } from 'react';

export default function TransactionPopup({
  removeAfterMs,
  hash,
  success,
  summary,
}: {
  removeAfterMs: number | null;
  hash: string;
  success?: boolean;
  summary?: string;
}) {
  const { chainId } = useConnectedChain();

  useEffect(() => {
    const message =
      summary ?? `Hash: ${hash.slice(0, 8)}...${hash.slice(58, 65)}`;

    const data = chainId
      ? {
          duration: removeAfterMs ?? Number.POSITIVE_INFINITY,
          description: (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={getEtherscanLink(chainId, hash, 'transaction')}
            >
              view on block explorer
            </Link>
          ),
        }
      : undefined;

    const toastState = success ? 'success' : 'error';
    toast[toastState](message, data);
  }, [chainId, hash, summary, success, removeAfterMs]);

  return null;
}
