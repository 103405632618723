import { ChainId } from '../constants';
import { TEST_WETH, DEV_WETH, MAIN_WETH } from '../contract_addresses.json';
import { validateAndParseAddress } from '../utils';
import { Currency } from './currency';
import invariant from 'tiny-invariant';

/**
 * Represents an ERC20 token with a unique address and some metadata.
 */
export class Token extends Currency {
  public readonly chainId: ChainId;
  public readonly address: string;

  public constructor(
    chainId: ChainId,
    address: string,
    decimals: number,
    symbol?: string,
    name?: string,
  ) {
    super(decimals, symbol, name);
    this.chainId = chainId;
    this.address = validateAndParseAddress(address);
  }

  /**
   * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
   * @param other other token to compare
   */
  public equals(other: Token): boolean {
    // short circuit on reference equality
    if (this === other) {
      return true;
    }
    return this.chainId === other.chainId && this.address === other.address;
  }

  /**
   * Returns true if the address of this token sorts before the address of the other token
   * @param other other token to compare
   * @throws if the tokens have the same address
   * @throws if the tokens are on different chains
   */
  public sortsBefore(other: Token): boolean {
    invariant(this.chainId === other.chainId, 'CHAIN_IDS');
    invariant(this.address !== other.address, 'ADDRESSES');
    return this.address.toLowerCase() < other.address.toLowerCase();
  }
}

/**
 * Compares two currencies for equality
 */
export function currencyEquals(
  currencyA: Currency,
  currencyB: Currency,
): boolean {
  if (currencyA instanceof Token && currencyB instanceof Token) {
    return currencyA.equals(currencyB);
  }
  if (currencyA instanceof Token) {
    return false;
  }
  if (currencyB instanceof Token) {
    return false;
  }
  return currencyA === currencyB;
}

export const WDEV = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    18,
    'WETH',
    'Wrapped Ether',
  ),
  [ChainId.XP]: new Token(ChainId.XP, DEV_WETH, 18, 'WXP', 'Wrapped XP'),
  [ChainId.BEAM_TESTNET]: new Token(
    ChainId.BEAM_TESTNET,
    TEST_WETH,
    18,
    'WBEAM',
    'Wrapped Beam',
  ),
  [ChainId.BEAM]: new Token(
    ChainId.BEAM,
    MAIN_WETH,
    18,
    'WBEAM',
    'Wrapped Beam',
  ),
};
