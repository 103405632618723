import CurrencyLogo from '../CurrencyLogo';
import { Currency } from '@beamswap/sdk';
import { css } from '@onbeam/styled-system/css';
import { flex } from '@onbeam/styled-system/patterns';
import React from 'react';

interface DoubleCurrencyLogoProps {
  margin?: boolean;
  size?: number;
  currency0?: Currency;
  currency1?: Currency;
}

export default function DoubleCurrencyLogo({
  currency0,
  currency1,
  size = 16,
  margin = false,
}: DoubleCurrencyLogoProps) {
  return (
    <div
      className={flex({
        position: 'relative',
        flexDirection: 'row',
        // TODO: Probably handle this in the parent component instead
        mr: margin ? '2' : undefined,
      })}
      style={
        currency0 && currency1
          ? {
              width: `${size * 1.5}px`,
            }
          : undefined
      }
    >
      {currency0 && (
        <CurrencyLogo
          currency={currency0}
          size={`${size}px`}
          className={css({ zIndex: 2 })}
        />
      )}
      {currency1 && (
        <CurrencyLogo
          currency={currency1}
          size={`${size}px`}
          className={css({ position: 'absolute' })}
          style={
            currency0
              ? {
                  left: `${size / 2}px`,
                }
              : undefined
          }
        />
      )}
    </div>
  );
}
