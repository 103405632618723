import { RowBetween } from '../Row';
import { IconButton, Tooltip } from '@onbeam/ui';
import { BackIcon } from '@onbeam/icons';
import { BeamLink } from '../BeamLink';
import { flex } from '@onbeam/styled-system/patterns';

export function FindPoolTabs() {
  return (
    <div
      className={flex({
        alignItems: 'center',
        justifyContent: 'space-evenly',
      })}
    >
      <RowBetween style={{ paddingBottom: '1rem' }}>
        <IconButton as={BeamLink} href="/pool">
          <BackIcon />
        </IconButton>
        <p>import pool</p>
        <Tooltip
          width={230}
          label="use this tool to find pairs that don't automatically appear in the interface."
        />
      </RowBetween>
    </div>
  );
}

export function AddRemoveTabs({ adding }: { adding: boolean }) {
  return (
    <div
      className={flex({
        alignItems: 'center',
        justifyContent: 'space-evenly',
      })}
    >
      <RowBetween style={{ paddingBottom: '1rem' }}>
        <IconButton as={BeamLink} href="/pool">
          <BackIcon />
        </IconButton>
        <p>{adding ? 'add' : 'remove'} liquidity</p>
        <Tooltip
          width={230}
          label={
            adding
              ? 'when you add liquidity, you are given pool tokens representing your position. these tokens automatically earn fees proportional to your share of the pool, and can be redeemed at any time.'
              : 'removing pool tokens converts your position back into underlying tokens at the current rate, proportional to your share of the pool. accrued fees are included in the amounts you receive.'
          }
        />
      </RowBetween>
    </div>
  );
}
