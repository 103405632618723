import { Button, ButtonProps } from '@onbeam/ui';
import { css } from '@onbeam/styled-system/css';
import { BeamNetworkIcon, WalletIcon } from '@onbeam/icons';
import { ConnectWalletButton } from '@onbeam/features';

export const ConnectButton = ({ size = 'sm' }: Pick<ButtonProps, 'size'>) => (
  <ConnectWalletButton
    size={size}
    renderWhenConnected={({
      formattedName,
      openChainModal,
      openAccountModal,
      chain,
    }) => (
      <>
        <Button
          onClick={openChainModal}
          size={size}
          aria-label={chain.name?.toLowerCase()}
        >
          <BeamNetworkIcon height={16} width={16} />
          <span className={css({ hideBelow: '[58em]' })}>
            {chain.name?.toLowerCase()}
          </span>
        </Button>
        <Button
          className={css({ hideBelow: '[25em]' })}
          onClick={openAccountModal}
          size={size}
          hasLed
          ledOn
        >
          {formattedName}
        </Button>
        <Button
          className={css({ hideFrom: '[calc(25em + 1px)]' })}
          onClick={openAccountModal}
          size={size}
          aria-label={formattedName}
          iconLeft={<WalletIcon height={16} width={16} />}
        />
      </>
    )}
  />
);
