import EthereumLogo from '../../assets/images/ethereum-logo.png';
import useHttpLocations from '../../hooks/useHttpLocations';
import { WrappedTokenInfo } from '../../state/lists/hooks';
import Logo from '../Logo';
import { Currency, DEV, Token } from '@beamswap/sdk';
import React, { useMemo } from 'react';
import { css, cx } from '@onbeam/styled-system/css';

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
  className,
}: {
  currency?: Currency;
  size?: string;
  style?: React.CSSProperties;
  className?: string;
}) {
  const uriLocations = useHttpLocations(
    currency instanceof WrappedTokenInfo ? currency.logoURI : undefined,
  );

  const srcs: string[] = useMemo(() => {
    if (currency === DEV) return [];

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return uriLocations;
      }

      // Token does not include correct url, only WrappedTokenInfo does
      return [];
    }
    return [];
  }, [currency, uriLocations]);

  if (currency === DEV) {
    return (
      <img
        src={EthereumLogo}
        width={size}
        height={size}
        style={style}
        alt={`${DEV?.symbol} logo`}
        className={cx(
          className,
          css({
            boxShadow: '[0px 6px 10px rgba(0, 0, 0, 0.075)]',
            borderRadius: 'xl',
          }),
        )}
      />
    );
  }

  return (
    <Logo
      size={size}
      srcs={srcs}
      alt={`${currency?.symbol ?? 'token'} logo`}
      style={style}
      className={cx(className, css({ borderRadius: 'full' }))}
    />
  );
}
