import { css } from '@onbeam/styled-system/css';
import { ChangeEventHandler, useCallback } from 'react';

interface InputSliderProps {
  value: number;
  onChange: (value: number) => void;
  step?: number;
  min?: number;
  max?: number;
  size?: number;
}

export default function Slider({
  value,
  onChange,
  min = 0,
  step = 1,
  max = 100,
  size = 16,
}: InputSliderProps) {
  const changeCallback = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      onChange(Number.parseInt(e.target.value));
    },
    [onChange],
  );

  return (
    <input
      className={css({
        WebkitAppearance: 'none',
        width: 'full',
        background: '[transparent]',
        cursor: 'pointer',
        '&:focus': {
          outline: 'none',
        },
        '&::-moz-focus-outer': {
          border: 0,
        },
        '&::-webkit-slider-thumb': {
          WebkitAppearance: 'none',
          height: 'var(--slider-size)',
          width: 'var(--slider-size)',
          backgroundColor: '[#565a69]',
          borderRadius: '[100%]',
          border: 'none',
          transform: 'translateY(-50%)',
          color: '[#2b2b2b]',
          '&:hover, &:focus': {
            boxShadow:
              '[0px 0px 1px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 24px 32px rgba(0, 0, 0, 0.04)]',
          },
        },
        '&::-moz-range-thumb': {
          height: 'var(--slider-size)',
          width: 'var(--slider-size)',
          backgroundColor: '[#565a69]',
          borderRadius: '[100%]',
          border: 'none',
          color: '[#2b2b2b]',
          '&:hover, &:focus': {
            boxShadow:
              '[0px 0px 1px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 24px 32px rgba(0, 0, 0, 0.04)]',
          },
        },
        '&::-ms-thumb': {
          height: 'var(--slider-size)',
          width: 'var(--slider-size)',
          backgroundColor: '[#565a69]',
          borderRadius: '[100%]',
          color: '[#2b2b2b]',
          '&:hover, &:focus': {
            boxShadow:
              '[0px 0px 1px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 24px 32px rgba(0, 0, 0, 0.04)]',
          },
        },
        '&::-webkit-slider-runnable-track': {
          background: '[linear-gradient(90deg, #6C7284, #40444F)]',
          height: '[2px]',
        },
        '&::-moz-range-track': {
          background: '[linear-gradient(90deg, #6C7284, #40444F)]',
          height: '[2px]',
        },
        '&::-ms-track': {
          width: 'full',
          borderColor: '[transparent]',
          color: '[transparent]',
          background: '[#6C7284]',
          height: '[2px]',
        },
        '&::-ms-fill-lower': {
          background: '[#6C7284]',
        },
        '&::-ms-fill-upper': {
          background: '[#40444F]',
        },
      })}
      type="range"
      value={value}
      style={
        {
          '--slider-size': `${size}px`,
          width: '100%',
          padding: '15px 0',
          border: 'none',
        } as React.CSSProperties
      }
      onChange={changeCallback}
      aria-labelledby="input slider"
      step={step}
      min={min}
      max={max}
    />
  );
}
