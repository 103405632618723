import { Link, LinkProps } from 'react-router-dom';

// Wrap the Link component from react-router-dom to use the `to` prop as `href`
export const BeamLink = ({
  href,
  ...props
}: Omit<LinkProps, 'to'> & { href: string }) => {
  return props.target === '_blank' ? (
    <a href={href} {...props} />
  ) : (
    <Link to={href} {...props} />
  );
};
