import {
  addMulticallListeners,
  errorFetchingMulticallResults,
  fetchingMulticallResults,
  removeMulticallListeners,
  toCallKey,
  updateMulticallResults,
} from './actions';
import { createReducer } from '@reduxjs/toolkit';

export interface MulticallState {
  callListeners?: {
    // on a per-chain basis
    [chainId: number]: {
      // stores for each call key the listeners' preferences
      [callKey: string]: {
        // stores how many listeners there are per each blocks per fetch preference
        [blocksPerFetch: number]: number;
      };
    };
  };

  callResults: {
    [chainId: number]: {
      [callKey: string]: {
        data?: string | null;
        blockNumber?: number;
        fetchingBlockNumber?: number;
      };
    };
  };
}

const initialState: MulticallState = {
  callResults: {},
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(
      addMulticallListeners,
      (
        state,
        {
          payload: {
            calls,
            chainId,
            options: { blocksPerFetch = 1 } = {},
          },
        },
      ) => {
        let listeners: MulticallState['callListeners'] = {};
        if (state.callListeners) {
          listeners = state.callListeners;
        } else {
          state.callListeners = {};
        }
        listeners[chainId] = listeners[chainId] ?? {};
        for (const call of calls) {
          const callKey = toCallKey(call);
          listeners[chainId][callKey] = listeners[chainId][callKey] ?? {};
          listeners[chainId][callKey][blocksPerFetch] =
            (listeners[chainId][callKey][blocksPerFetch] ?? 0) + 1;
        }
      },
    )
    .addCase(
      removeMulticallListeners,
      (
        state,
        {
          payload: {
            chainId,
            calls,
            options: { blocksPerFetch = 1 } = {},
          },
        },
      ) => {
        let listeners: MulticallState['callListeners'] = {};
        if (state.callListeners) {
          listeners = state.callListeners;
        } else {
          state.callListeners = {};
        }

        if (!listeners[chainId]) return;
        for (const call of calls) {
          const callKey = toCallKey(call);
          if (!listeners[chainId][callKey]) continue;
          if (!listeners[chainId][callKey][blocksPerFetch]) continue;

          if (listeners[chainId][callKey][blocksPerFetch] === 1) {
            delete listeners[chainId][callKey][blocksPerFetch];
          } else {
            listeners[chainId][callKey][blocksPerFetch]--;
          }
        }
      },
    )
    .addCase(
      fetchingMulticallResults,
      (state, { payload: { chainId, fetchingBlockNumber, calls } }) => {
        state.callResults[chainId] = state.callResults[chainId] ?? {};
        for (const call of calls) {
          const callKey = toCallKey(call);
          const current = state.callResults[chainId][callKey];
          if (!current) {
            state.callResults[chainId][callKey] = {
              fetchingBlockNumber,
            };
          } else {
            if ((current.fetchingBlockNumber ?? 0) >= fetchingBlockNumber)
              return;
            state.callResults[chainId][callKey].fetchingBlockNumber =
              fetchingBlockNumber;
          }
        }
      },
    )
    .addCase(
      errorFetchingMulticallResults,
      (state, { payload: { fetchingBlockNumber, chainId, calls } }) => {
        state.callResults[chainId] = state.callResults[chainId] ?? {};
        for (const call of calls) {
          const callKey = toCallKey(call);
          const current = state.callResults[chainId][callKey];
          if (!current) continue; // only should be dispatched if we are already fetching
          if (current.fetchingBlockNumber === fetchingBlockNumber) {
            current.fetchingBlockNumber = undefined;
            current.data = null;
            current.blockNumber = fetchingBlockNumber;
          }
        }
      },
    )
    .addCase(
      updateMulticallResults,
      (state, { payload: { chainId, results, blockNumber } }) => {
        state.callResults[chainId] = state.callResults[chainId] ?? {};
        for (const callKey of Object.keys(results)) {
          const current = state.callResults[chainId][callKey];
          if ((current?.blockNumber ?? 0) > blockNumber) continue;
          state.callResults[chainId][callKey] = {
            data: results[callKey],
            blockNumber,
          };
        }
      },
    ),
);
