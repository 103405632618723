import { useAccount } from 'wagmi';
import { useCurrencyBalance } from '../../state/wallet/hooks';
import { Currency, Pair } from '@beamswap/sdk';

import { Button, DappInputField } from '@onbeam/ui';
import { WrappedTokenInfo } from '../../state/lists/hooks';
import { CurrencyModal } from '../CurrencyModal';

interface CurrencyInputPanelProps {
  value: string;
  onUserInput: (value: string) => void;
  onMax?: () => void;
  label?: string;
  onCurrencySelect?: (currency: Currency) => void;
  currency?: Currency | WrappedTokenInfo;
  disableCurrencySelect?: boolean;
  hideBalance?: boolean;
  pair?: Pair | null;
  hideInput?: boolean;
  otherCurrency?: Currency | null;
  id: string;
  showCommonBases?: boolean;
}

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax = () => {},
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideInput = false,
  id,
}: CurrencyInputPanelProps) {
  const { address } = useAccount();

  const selectedCurrencyBalance = useCurrencyBalance(
    address ?? undefined,
    currency ?? undefined,
  );

  const showSelectModal = !disableCurrencySelect && onCurrencySelect;

  return (
    <DappInputField
      autoComplete="off"
      id={id}
      value={!hideInput ? value : undefined}
      currency={currency?.symbol}
      nativeBalance={
        selectedCurrencyBalance
          ? Number(selectedCurrencyBalance?.toSignificant(6))
          : undefined
      }
      topRightAddon={
        showSelectModal && (
          <CurrencyModal
            currency={currency}
            onCurrencySelect={onCurrencySelect}
          />
        )
      }
      bottomRightAddon={
        <Button variant="outline" size="sm" onClick={onMax}>
          max
        </Button>
      }
      onChange={(event) => {
        onUserInput(event.target.value);
      }}
    />
  );
}
