import { useActiveWeb3React } from '../../hooks';
import useConnectedChain from '../../hooks/useConnectedChain';
import { useAddPopup, useBlockNumber } from '../application/hooks';
import { AppDispatch, AppState } from '../index';
import { checkedTransaction, finalizeTransaction } from './actions';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export function shouldCheck(
  lastBlockNumber: number,
  tx: { addedTime: number; receipt?: {}; lastCheckedBlockNumber?: number },
): boolean {
  if (tx.receipt) return false;
  if (!tx.lastCheckedBlockNumber) return true;
  const blocksSinceCheck = lastBlockNumber - tx.lastCheckedBlockNumber;
  if (blocksSinceCheck < 1) return false;
  const minutesPending = (new Date().getTime() - tx.addedTime) / 1000 / 60;
  if (minutesPending > 60) {
    // every 10 blocks if pending for longer than an hour
    return blocksSinceCheck > 9;
  }
  if (minutesPending > 5) {
    // every 3 blocks if pending more than 5 minutes
    return blocksSinceCheck > 2;
  }
  // otherwise every block
  return true;
}

export default function Updater(): null {
  const { library } = useActiveWeb3React();
  const { chainId } = useConnectedChain();

  const lastBlockNumber = useBlockNumber();

  const dispatch = useDispatch<AppDispatch>();
  const state = useSelector<AppState, AppState['transactions']>(
    (state) => state.transactions,
  );

  const transactions = useMemo(
    () => (chainId ? state[chainId] ?? {} : {}),
    [state, chainId],
  );

  // show popup on confirm
  const addPopup = useAddPopup();

  useEffect(() => {
    if (!chainId || !library || !lastBlockNumber) return;

    const checkTransactionReceipt = async (hash: string) => {
      try {
        const receipt = await library.getTransactionReceipt(hash);
        if (receipt) {
          dispatch(
            finalizeTransaction({
              chainId,
              hash,
              receipt: {
                blockHash: receipt.blockHash,
                blockNumber: receipt.blockNumber,
                contractAddress: receipt.contractAddress,
                from: receipt.from,
                status: receipt.status,
                to: receipt.to,
                transactionHash: receipt.transactionHash,
                transactionIndex: receipt.transactionIndex,
              },
            }),
          );

          addPopup(
            {
              txn: {
                hash,
                success: receipt.status === 1,
                summary: transactions[hash]?.summary,
              },
            },
            hash,
          );
        } else {
          dispatch(
            checkedTransaction({
              chainId,
              hash,
              blockNumber: lastBlockNumber,
            }),
          );
        }
      } catch (error) {
        console.error(`failed to check transaction hash: ${hash}`, error);
      }
    };

    for (const hash of Object.keys(transactions).filter((hash) =>
      shouldCheck(lastBlockNumber, transactions[hash]),
    )) {
      checkTransactionReceipt(hash);
    }
  }, [chainId, library, transactions, lastBlockNumber, dispatch, addPopup]);

  return null;
}
