import { PropsWithChildren } from 'react';
import { useAccount } from 'wagmi';
import { ConnectWalletApplet } from '../../components/ConnectWalletApplet';

export const ConnectHandler = ({ children }: PropsWithChildren) => {
  const { address } = useAccount();

  if (!address) return <ConnectWalletApplet />;

  return children;
};
